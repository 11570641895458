.aktuelles-artikel {
    display: flex;
    flex-direction: column;
    /* Show a horizontal bar on the bottom of each article */
    padding-bottom: 1em;
    border-bottom: 1px solid #000;
    margin-bottom: 2em;
}

.aktuelles-artikel > time {
    /* Display the article date above the header, even though the html has the date first for  */
    order: -2;
    font-size: 1em;
    font-style: italic;
}

/* The header of each article */
.aktuelles-artikel > h1 {
    order: -1;
    font-size: 1.5em;
    color: #111;
    margin-bottom: 0.5em;
}

